<template>
  <div v-if="!isLoading">
    <b-row>
      <b-col cols="12">
        <div class="mb-3">
          <input autofocus type="text" class="form-control" placeholder="Cari Kelurahan" v-model="search" />
        </div>
      </b-col>
      <b-col cols="12" v-for="village in searchVillage" :key="village.id">
        <router-link :to="`/kelurahan-suara/kelurahan/${village.id}/input-suara/calon/2`" class="text-decoration-none">
          <b-card class="mb-2">
            <b-card-text class="text-center">
              {{ village.name }}
            </b-card-text>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: {
        villages: [],
      },
      search: null,
      isLoading: true,
    };
  },
  mounted() {
    this.whenMounted();
  },
  computed: {
    searchVillage() {
      return this.list.villages.filter((village) => {
        if (!this.search) return true;
        return village.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    async whenMounted() {
      await this.showLoading("Memuat data...");
      try {
        const resp = await this.$http.get(
          process.env.VUE_APP_API_URL + "input-suara/verifikator/villages"
        );
        this.list.villages = resp.data;
        this.isLoading = false;
      } catch (error) {
        await iziToast.error({
          title: "Error",
          theme: "dark",
          message: serializeErrorMessage(error),
        });
      }
    },
    async showLoading(text = "Memuat Data...") {
      await this.$swal.fire({
        title: "Mohon Tunggu",
        text: text,
        timer: 500,
        timerProgressBar: true,
        showConfirmButton: false,
      });
      return Promise.resolve("success");
    },
    async openAlert() {
      await this.$swal
        .fire({
          title: "Perhatian!",
          text: "Pastikan anda mengisi data dengan benar dan teliti.",
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: "Mulai",
          cancelButtonText: "Tidak",
        })
        .then((result) => {
          if (result.value) {
            this.$router.push(
              `/input-suara/calon/${this.$route.params.level}/form`
            );
          }
        });
    },
  },
};
</script>